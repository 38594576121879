import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import { graphql, Link, navigate } from "gatsby";
import React, { useState } from "react";
import tw from "twin.macro";
import { Layout } from "../components/Layout";
import { Space } from "../components/Space";
import { A, Body } from "../components/Typography";
import { theme } from "../utils/theme";
import { Responsive } from "../utils/Responsive";
import Metadata from "../components/Metadata";

const Discover = ({ data }) => {
    const articles = data.allContentfulArticle.edges.map(({ node }) => node);
    return (
        <Layout>
            <Metadata title="Discover" desc="" />
            <Bg />
            <Responsive
                sm={
                    <>
                        <Space.V
                            size="none"
                            tw="mt-32 px-4"
                            style={{ alignItems: "center" }}
                        >
                            {articles.map((article) => (
                                <ArticleCard key={article.id} data={article} />
                            ))}
                        </Space.V>
                    </>
                }
                md={
                    <ArticlesWrapper>
                        {articles.map((article, i) => (
                            <ArticleCard
                                key={article.id}
                                data={article}
                                imageLeft={i % 2 === 1}
                            />
                        ))}
                    </ArticlesWrapper>
                }
            />
        </Layout>
    );
};

export default Discover;

const Bg = styled.div`
    ${tw`fixed h-screen w-screen inset-0`};
    z-index: -1;
    background: ${theme.colors.pistacio};
`;

const ArticlesWrapper = styled.div`
    ${tw`grid`};
    grid-template-columns: 1fr 1fr;
`;

interface ArticleCardProps {
    data: any;
    imageLeft?: boolean;
}

const ArticleCard: React.FC<ArticleCardProps> = ({ data, imageLeft }) => {
    const [isImageVisible, setImageVisible] = useState<boolean>(false);
    const {
        title,
        excerpt: { excerpt },
        thumbnail,
        slug,
    } = data;
    const path = `/discover/${slug}`;
    return (
        <Responsive
            sm={
                <StyledArticleCard
                    style={{
                        marginTop: "calc(100% / 2)",
                        textAlign: "center",
                        width: "50vw",
                        cursor: "pointer",
                    }}
                    onClick={() =>
                        !isImageVisible ? setImageVisible(true) : navigate(path)
                    }
                >
                    <AnimatePresence>
                        {isImageVisible && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.1 }}
                                style={{ zIndex: -1 }}
                                tw="fixed w-screen h-screen grid inset-0 pointer-events-none"
                            >
                                <Image src={thumbnail.resize.src} />
                            </motion.div>
                        )}
                    </AnimatePresence>
                    {!isImageVisible ? (
                        <>
                            <A>{title}</A>
                            <Body tw="mt-4 max-w-sm">{excerpt}</Body>
                        </>
                    ) : (
                        <div tw="">
                            <A tw="text-white">{title}</A>
                            <Body tw="mt-4 max-w-sm underline text-white">
                                {excerpt}
                            </Body>
                        </div>
                    )}
                </StyledArticleCard>
            }
            md={
                <StyledArticleCard style={{ marginTop: "calc(100vh / 3)" }}>
                    <AnimatePresence>
                        {isImageVisible && (
                            <HoveredImage
                                src={thumbnail.resize.src}
                                imageLeft={imageLeft}
                            />
                        )}
                    </AnimatePresence>
                    <Link to={path}>
                        <Hoverable
                            onMouseOver={() => setImageVisible(true)}
                            onMouseOut={() => setImageVisible(false)}
                        >
                            <A>{title}</A>
                            <Body tw="mt-4 max-w-sm text-center">
                                {excerpt}
                            </Body>
                        </Hoverable>
                    </Link>
                </StyledArticleCard>
            }
        />
    );
};

const StyledArticleCard = styled.div`
    ${tw`flex flex-col justify-center items-center`};
`;

const Hoverable = styled(Space.V)`
    ${tw`items-center`};
    width: fit-content;
    height: fit-content;
`;

const HoveredImage = ({ src, imageLeft }) => {
    return (
        <HoveredImageWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
        >
            {!imageLeft && <div />}
            <Image src={src} />
            {imageLeft && <div />}
        </HoveredImageWrapper>
    );
};

const HoveredImageWrapper = styled(motion.div)`
    ${tw`fixed w-full h-full grid inset-0 pointer-events-none`};
    grid-template-columns: 1fr 1fr;
`;

const Image = styled.div<{ src: string }>`
    ${tw`grid w-full h-full`};
    background-image: ${({ src }) => `url('${src}')`};
    background-position: center;
    background-size: cover;
`;

export const query = graphql`
    query DiscoverPageQuery {
        contentfulDiscoverPage {
            id
            # description {
            #     description
            # }
        }

        allContentfulArticle {
            edges {
                node {
                    id
                    title
                    slug
                    thumbnail {
                        resize(width: 1800) {
                            src
                        }
                    }
                    excerpt {
                        excerpt
                    }
                }
            }
        }
    }
`;
